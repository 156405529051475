<template>
    <div class="register">
        <SquareLogo class="toplogo" />
        <form class="form">
            <a-form-item>
                <span class="label" slot="label">{{$t('register.label.account')}}</span>
                <a-input size="large" v-model="address" :disabled="disableAddressIpt" />
            </a-form-item>
            <a-form-item v-if="referer">
                <span class="label" slot="label">{{$t('register.label.referer')}}</span>
                <a-input size="large" v-model="referer" :disabled="disableRefererIpt" />
            </a-form-item>
            <a-form-item>
                <span class="label" slot="label">{{$t('register.label.password')}}</span>
                <a-input-password size="large" ref="password" autocomplete="off" v-model="password" />
            </a-form-item>
            <!-- <a-form-item>
                <span class="label" slot="label">{{$t('register.label.oxaddress')}}</span>
                <a-input size="large" v-model="oxaddress" placeholder="0x" />
            </a-form-item> -->
            <!-- <a-form-item>
                <span class="label" slot="label">{{$t('register.label.cpassword')}}</span>
                <a-input-password size="large" ref="cpassword" autocomplete="off" v-model="cpassword" />
            </a-form-item> -->
            <div class="btn-primary mg30" @click="submit">
                {{$t('common.register_btn')}}
            </div>
            <div class="color-gray">
                {{$t('register.already_registered')}} 
                <span @click="goLogin" class="color-white">{{$t('common.login_btn')}}</span>
            </div>
        </form>
      </div>
</template>
<script>
import SquareLogo from '../components/SquareLogo.vue';
import Shelldao from "../web3/tron/shelldao";

export default {
    name: 'Register',
    data(){
        return {
            referer: this.$route.params.i,
            address: '',
            password: '',
            // cpassword: '',
            // oxaddress: '',
            disableAddressIpt: true,
            disableRefererIpt: true,
            loading: false,
        }
    },
    components:{
        SquareLogo
    },
    mounted(){
        Shelldao.init((res)=>{
            this.address = res.gWalletAddress;
        });
        if (!this.referer){
            this.disableRefererIpt = false
        }
        setTimeout(()=>{
            if (this.address == ''){
                this.disableAddressIpt = false
            }
        },3000)
    },
    methods:{
        submit(){
            this.loading = true;
            if(!this.address || !this.password){
                this.$message.error(this.$t('register.tips.iptError'));
                return;
            }
            let data = {
                address: this.address,
                referer: this.referer,
                password: this.password,
                // oxaddress: this.oxaddress
            };
            let _this = this;
            this.$http.call(this.$http.api.register,{data:data}).then(function(res){
                let resp = res.data;
                if(resp.code=='200'){
                    // _this.$store.commit('setUserinfo', resp.data.user);
                    _this.$message.success(_this.$t('register.tips.success'));
                    setTimeout(()=>{
                        _this.$store.commit('setToken', resp.data.token);
                        _this.$router.push({name:'home'});
                    },2000);
                }else{
                    _this.$message.error(resp.data);
                }
            },function(res){
                console.log('something goes wrong...');
                console.log(res);
            }).then(()=>{
                _this.loading = false;
            });
        },
        goLogin(){
            this.$router.push('/login');
        }
    }
}
</script>
<style lang="less">
.register{
    width: 100%;
    .toplogo {
        padding-top: 256px;
    }
    .form {
        padding: 50px;
        margin: 32px auto;
        width: 90%;
        background: #fae38883;
        border-radius: 30px;
        box-shadow: 1PX 1PX 4PX #fff;
        color: #fff;
        .label {
            color: #fff;
        }
        .color-gray {
            color: #dadada;
        }
    }
}
</style>
